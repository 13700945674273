<template>
    <div class="menuManagement-container">
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <vab-query-form>
                    <vab-query-form-top-panel :span="12">
                        <el-button icon="el-icon-plus" type="primary" @click="handleAdd(null,'Root')">
                            添加Root组件
                        </el-button>
                    </vab-query-form-top-panel>
                </vab-query-form>
                <el-table :data="list"
                          border
                          row-key="id"
                          default-expand-all
                          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                    <!--基本信息-->
                    <el-table-column show-overflow-tooltip
                                     width="150"
                                     prop="meta.title"
                                     label="标题"></el-table-column>
                    <el-table-column show-overflow-tooltip
                                     width="200"
                                     prop="path"
                                     label="路径"></el-table-column>
                    <el-table-column show-overflow-tooltip width="100" label="是否隐藏" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ row.hidden ? '是' : '否' }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip width="100" label="组件类型" align="center">
                        <template #default="{ row }">
                            <span>
                                {{ row.itype===0 ? '菜单栏目' : '组件栏目' }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip
                                     min-width="200"
                                     prop="component"
                                     label="文件路径"></el-table-column>


                    <el-table-column show-overflow-tooltip label="操作" min-width="150">
                        <template #default="{ row }">
                            <el-button v-if="row.itype==0" type="text" @click="handleAdd(row.id,row.meta.title)">添加组件</el-button>
                            <el-button type="text" @click="handleEdit(row)">编辑</el-button>
                            <el-button type="text" @click="handleDelete(row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <edit ref="edit" @fetch-data="fetchData"></edit>
    </div>
</template>

<script>
    import { formatToTree } from '@/utils';
    import { getModules, deleteModule } from '@/api/menu';
    import Edit from './components/MenuEdit';

    export default {
        name: 'MenuManagement',
        components: { Edit },
        data() {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'label',
                },
                list: []
            }
        },
        async created() {
            this.fetchData();
        },
        methods: {
            handleAdd(parentId, parentName) {
                this.$refs['edit'].showAdd(parentId, parentName);
            },
            handleEdit(row) {
                this.$refs['edit'].showEdit(row);
            },
            handleDelete(row) {
                if (row.id) {
                    this.$baseConfirm('你确定要删除当前项吗', null, async () => {
                        const { isSucceed, message } = await deleteModule({ id: row.id });
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        this.fetchData();
                    });
                }
            },
            async fetchData() {
                const { isSucceed, message, result } = await getModules();
                if (!isSucceed) return this.$baseMessage(message, 'error');
                let tree = formatToTree(result);
                //console.log('[tree]' + JSON.stringify(tree));
                this.list = tree;
            },
            handleNodeClick() {
                this.fetchData();
            },
        },
    }
</script>
