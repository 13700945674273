<template>
    <el-dialog :title="title"
               :visible.sync="dialogFormVisible"
               width=500px
               @close="close">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="上级组件">
                <label>{{form.parentName}}</label>
                <!--<el-input v-model="form.parentId" autocomplete="off" readOnly></el-input>-->
            </el-form-item>

            <el-form-item label="组件类型" prop="itype">
                <el-select v-model="form.itype" placeholder="请选择" clearable filterable @change="itypeChange">
                    <el-option v-for="item in options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>


            <el-form-item label="英文名称" prop="name">
                <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="组件路径" prop="path">
                <el-input v-model="form.path" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="组件文件" prop="component">
                <el-input v-model="form.component" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否隐藏">
                <el-checkbox v-model="form.hidden">
                    隐藏
                </el-checkbox>
            </el-form-item>
            <el-form-item label="是否禁用">
                <el-checkbox v-model="form.isDisable">
                    禁用
                </el-checkbox>
            </el-form-item>


            <el-form-item label="组件标题" prop="meta.title">
                <el-input v-model="form.meta.title" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="禁止缓存">
                <el-checkbox v-model="form.meta.noKeepAlive">
                    禁止
                </el-checkbox>
            </el-form-item>
            <el-form-item label="组件标识">
                <el-input v-model="form.meta.badge" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="组件图标">
                <el-input v-model="form.meta.icon" autocomplete="off"></el-input>
            </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import { addModule, editModule } from '@/api/menu';

    export default {
        name: 'MenuEdit',
        data() {
            return {
                options: [{
                    value: 0,
                    label: '菜单栏目'
                },
                {
                    value: 1,
                    label: '组件栏目'
                }],
                form: {
                    id: 0,
                    meta: {
                        title: "",
                        icon: "",
                        affix: false,
                        noKeepAlive: false,
                        badge: ""
                    },
                    name: "",
                    itype: 0,
                    path: "",
                    component: "layouts",
                    hidden: false,
                    alwaysShow: false,
                    isDisable: false,
                    parentId: null,
                    parentName: 'Root'
                },
                rules: {
                    name: [{ required: true, trigger: 'blur', message: '请输入组件名称' }],
                    itype: [{ required: true, trigger: 'blur', message: '请选择组件类型' }],
                    path: [{ required: true, trigger: 'blur', message: '请输入组件路径' }],
                    component: [{ required: true, trigger: 'blur', message: '请输入组件文件' }],
                    meta: {
                        title: [{ required: true, trigger: 'blur', message: '请输入组件标题' }],
                    }
                },
                title: '',
                dialogFormVisible: false,
            }
        },
        created() { },
        methods: {
            itypeChange(v) {
                switch (parseInt(v)) {
                    case 0: //菜单栏目
                        this.form.component = this.form.parentId ? 'layouts/empty' : 'layouts';
                        break;
                    default://组件栏目
                        this.form.component = '';
                        break;
                }
            },
            showAdd(parentId, parentName) {
                this.title = '添加';
                this.form.parentId = parentId;
                this.form.parentName = parentName;
                if (this.form.parentId) this.form.component = 'layouts/empty';
                this.dialogFormVisible = true;
            },
            showEdit(row) {
                this.title = '编辑';
                this.form = Object.assign({}, row);
                this.dialogFormVisible = true;
            },
            close() {
                this.$refs['form'].resetFields();
                this.form = this.$options.data().form;
                this.dialogFormVisible = false;
            },
            save() {
                this.$refs['form'].validate(async (valid) => {
                    if (!valid) return false;
                    if (this.form.id === 0) {
                        const { isSucceed, message } = await addModule(this.form);
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    } else {
                        const { isSucceed, message } = await editModule(this.form)
                        this.$baseMessage(message, (isSucceed ? 'success' : 'error'));
                        if (isSucceed) this.close();
                    }
                    this.$emit('fetch-data');
                })
            },
        },
    }
</script>
